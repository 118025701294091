import React, { FC } from "react";

import "@/assets/styles/main.css";
import "react-toastify/dist/ReactToastify.css";

import { Navigate } from "react-router-dom";
import { Route, Routes } from "react-router";

import { Toast } from "@/ui";
import { Layout } from "@/components";
import { routes } from "@/pages/routes";
import { LoginPage } from "@/pages/login";
import { TwoFAPage } from "@/pages/twoFAPage";
import { OtpRequestPage } from "@/pages/otpRequest";
import { ApplicationsPage } from "@/pages/applications";
import { ProfilePage } from "@/pages/profile";
import { RegistrationPage } from "@/pages/registration";
import { ForgotPasswordPage } from "@/pages/forgotPassword";
import { ResetLinkSentPage } from "@/pages/resetLinkSent";
import { ConfirmForgotPasswordPage } from "@/pages/confirmForgotPassword";
import { MasqueradePage } from "@/pages/masquerade";

const App: FC = () => {
  return (
    <>
      <Toast />
      <Layout>
        <Routes>
          <Route path={routes.masquerade} element={<MasqueradePage />} />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.registration} element={<RegistrationPage />} />
          <Route
            path={routes.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path={routes.resetLinkSent} element={<ResetLinkSentPage />} />
          <Route
            path={routes.confirmForgotPassword}
            element={<ConfirmForgotPasswordPage />}
          />
          <Route path={routes.otpRequest} element={<OtpRequestPage />} />
          <Route path={routes.twoFA} element={<TwoFAPage />} />
          <Route path={routes.applications} element={<ApplicationsPage />} />
          <Route path={routes.profile} element={<ProfilePage />} />
          {/*<Route path={routes.error} element={<ErrorPage />} />*/}
          {/* Добавление редиректа на страницу входа для несуществующих маршрутов */}
          <Route path="*" element={<Navigate to={routes.login} replace />} />
        </Routes>
      </Layout>
    </>
  );
};

export default App;
