export const loginUrl = "api/auth/v1/login/password";
export const registerationUrl = "api/auth/v1/register/user";
export const twoFARequestUrl = "api/auth/v1/user/otp-send";
export const otpRequestUrl = "api/auth/v1/login/otp-code";
export const otpConfirmUrl = "api/auth/v1/user/otp-confirm";
export const logoutUrl = "api/auth/v1/user/logout";
export const applicationsUrl = "api/auth/v1/user/applications/list";
export const getUserUrl = "api/auth/v1/user/info";
export const editPasswordUrl = "api/auth/v1/user/password/reset";
export const editEmailUrl = "api/auth/v1/user/field/email";
export const editPhoneUrl = "api/auth/v1/user/field/phone";
export const resetRequestUrl = "api/auth/v1/password/send-reset-link";
export const resetConfirmUrl = "api/auth/v1/password/reset";
export const otpEmailUrl = "api/auth/v1/user/otp/email/send";
export const confirmEmailUrl = "api/auth/v1/user/otp/email/confirm";
export const otpPhoneUrl = "api/auth/v1/user/otp/phone/send";
export const confirmPhoneUrl = "api/auth/v1/user/otp/phone/confirm";
export const authCheckUrl = "api/auth/v1/login/authcheck";
export const masqueradeUrl = "api/auth/v1/user/masquerade";
