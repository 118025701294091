import { NavigateFunction } from "react-router";

import { IAuth } from "@/store/auth";
import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { store } from "@/store/configureStore";
import { otpRequestUrl } from "@/endpoints/apiUrl";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { FormSchemaOtpRequestPage } from "@/constants/validateSchema";

export const otpRequestThunk = createAsyncThunk(
  "auth/otpRequest",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaOtpRequestPage;
    navigate: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<CustomResponse<IAuth>>(() =>
        apiFetch({
          url: `${otpRequestUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        localStorage.setItem("access_token", response.data.access_token);

        if (response.data?.two_fa_required === true) {
          navigate(routes.twoFA);
        }
        if (response.data?.two_fa_required === false) {
          if (response.data?.redirect_to) {
            let redirectTo = response.data.redirect_to;

            window.location.href = `${redirectTo}&returnUrl=${window.location.origin}${routes.profile}`;
          } else {
            navigate(routes.profile);
          }
        }

        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
