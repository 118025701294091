import style from "./AuthContainer.module.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { useSelector } from "react-redux";
import {
  authorizedCommonRoutes,
  routes,
  unchechAuthRoutes,
} from "@/pages/routes";
import { Loader } from "@/ui";
import { logoutThunk, getAuthState, authSlice } from "@/store/auth";
import useFetch from "@/hooks/useFetch.hook";
import { authCheckUrl } from "@/endpoints/apiUrl";
import { errorSlice } from "@/store/error";
import { showNotification } from "@/services/notification";
import { PARAM_RETURN_URL } from "@/constants/messages";
import { getReturnUrlWithWrap } from "@/utils/getReturnUrlWithWrap";

interface AuthContainerProps {
  children: React.ReactNode;
}

export const AuthContainer = ({ children }: AuthContainerProps) => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getAuthState);
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const returnUrlParams = params.get(PARAM_RETURN_URL);
  const access_token = localStorage.getItem("access_token");

  // TODO: Вынести в диспатч через стор логику ауфчек
  const { isLoading: isLoadingAuthCheck, fetch: fetchAuthCheck } = useFetch(
    authCheckUrl,
    {
      method: "GET",
      headers: {
        "Cache-Control": "no-store",
        Pragma: "no-cache",
        Expires: "0",
      },
    },
  );

  const [isRedirectInProgress, setIsRedirectInProgress] =
    useState<boolean>(false);

  let authCheckResponse: null | number = null;
  const checkIsAuthenticated = async () => {
    if (authCheckResponse === null) {
      authCheckResponse = await fetchAuthCheck();
    }

    dispatch(authSlice.actions.authCheckDone());
    return authCheckResponse === 1;
  };

  const buildUrlWithParams = (url: string) => {
    const searchParams = new URLSearchParams(location.search);
    return `${url}?${searchParams.toString()}`;
  };

  // Функция для проверки авторизации
  const checkAuth = async () => {
    try {
      const isAuthenticated = await checkIsAuthenticated();

      if (!isAuthenticated) {
        // Не авторизован
        navigate(buildUrlWithParams(routes.login));
        if (Object.keys(auth.data).length) {
          dispatch(logoutThunk(navigate));
          return;
        }
      } else {
        // Ensure if no need to redirect somewhere else before redirecting to internal path.
        ensureRedirect();

        // Авторизован
        if (authorizedCommonRoutes.includes(window.location.pathname)) {
          navigate(buildUrlWithParams(routes.profile));
        } else {
          navigate(buildUrlWithParams(window.location.pathname));
        }
      }
    } catch (error) {
      showNotification("AuthCheckStatusError");

      dispatch(logoutThunk(navigate));
    }
  };

  const getStoredReturlUrl = () => {
    return localStorage.getItem(PARAM_RETURN_URL) || null;
  };

  const ensureRedirect = () => {
    const returnUrlValue = getStoredReturlUrl();
    if (returnUrlValue) {
      setIsRedirectInProgress(true);
      localStorage.removeItem(PARAM_RETURN_URL);
      window.location.href = getReturnUrlWithWrap(returnUrlValue);
      return;
    }
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());

    // Check if need to redirect with pre-stored redirectUrl from previous session
    checkIsAuthenticated()
      .then(isAuthenticated => {
        if (isAuthenticated) {
          ensureRedirect();
        }
      })
      .then(() => {
        // Store a new redirect url if it exists in URL
        if (returnUrlParams) {
          localStorage.setItem(PARAM_RETURN_URL, returnUrlParams);
        }

        if (!unchechAuthRoutes.includes(window.location.pathname)) {
          checkAuth();
        }
      });
  }, []);

  if (isLoadingAuthCheck || isRedirectInProgress) {
    return (
      <div className={style.container}>
        <Loader.Main className={style.loader}></Loader.Main>
      </div>
    );
  }

  return children;
};
