import { NavigateFunction } from "react-router";
import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { masqueradeUrl } from "@/endpoints/apiUrl";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { FormSchemaMasqueradePage } from "@/constants/validateSchema";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";

export const masqueradeThunk = createAsyncThunk(
  "auth/masquerade",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaMasqueradePage;
    navigate: NavigateFunction;
  }) => {
    try {
      const response = await callApiFn<CustomResponse<any>>(() =>
        apiFetch({
          url: `${masqueradeUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify({
              user_id: Number(data.user_id),
            }),
          },
        }),
      );

      if (response.success) {
        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
        }

        if (response.data?.two_fa_required === true) {
          navigate(routes.twoFA);
        }
        if (response.data?.two_fa_required === false) {
          if (response.data?.redirect_to) {
            let redirectTo = response.data.redirect_to;
            const hrefRedirectWithReturnUrl = `${redirectTo}&returnUrl=${window.location.origin}${routes.profile}`;
            window.location.href = hrefRedirectWithReturnUrl;
          } else {
            navigate(routes.profile);
          }
        }
        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
