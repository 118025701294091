import { AuthState, IAuth } from "../type";

export const logoutThunkReducer = {
  pending: (state: AuthState) => {
    state.loading = true;
  },
  fulfilled: (state: AuthState) => {
    state.data = {} as IAuth;
    state.loading = false;
    state.isAuth = false;
  },
  rejected: (state: AuthState) => {
    state.loading = false;
  },
};
