import { Link } from "react-router-dom";

import style from "./LinkBackPage.module.css";
import { errorSlice } from "@/store/error";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";

interface LinkBackPageProps {
  to: string;
  label?: string;
}

export const LinkBackPage = ({
  to,
  label = "Вернуться назад",
}: LinkBackPageProps) => {
  const dispatch = useAppDispatch();
  return (
    <div className={style.other}>
      <Link
        to={to}
        className={style.link}
        onClick={() => {
          dispatch(errorSlice.actions.resetError());
        }}
      >
        {label}
      </Link>
    </div>
  );
};
