import { NavigateFunction } from "react-router";

import { IAuth } from "@/store/auth";
import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { loginUrl } from "@/endpoints/apiUrl";
import { store } from "@/store/configureStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomResponse } from "@/services/apiFetch.type";
import { FormSchemaLoginPage } from "@/constants/validateSchema";
import { apiFetch, callApiFn, FetchError } from "@/services/apiFetch";
import { PARAM_RETURN_URL } from "@/constants/messages";
import { get } from "react-hook-form";

export const loginThunk = createAsyncThunk(
  "auth/login",
  async ({
    data,
    navigate,
  }: {
    data: FormSchemaLoginPage;
    navigate: NavigateFunction;
  }) => {
    try {
      const getStoredReturlUrl = () => {
        return localStorage.getItem(PARAM_RETURN_URL) || null;
      };

      const response = await callApiFn<CustomResponse<IAuth>>(() =>
        apiFetch({
          url: `${loginUrl}`,
          options: {
            method: "POST",
            body: JSON.stringify(data),
          },
        }),
      );

      if (response.success) {
        if (response.data.access_token) {
          localStorage.setItem("access_token", response.data.access_token);
        }
        if (response.data.trust_token) {
          localStorage.setItem("trust_token", response.data.trust_token);
        }

        if (response.data?.two_fa_required === true) {
          navigate(routes.twoFA);
        }
        if (response.data?.two_fa_required === false) {
          if (response.data?.redirect_to) {
            let returnUrl = getStoredReturlUrl();
            if (returnUrl == null) {
              returnUrl = `${window.location.origin}${routes.profile}`;
            }
            else {
              localStorage.removeItem(PARAM_RETURN_URL)
            }

            let redirectTo = response.data.redirect_to;
            const hrefRedirectWithReturnUrl = `${redirectTo}&returnUrl=${encodeURIComponent(returnUrl)}`;
            window.location.href = hrefRedirectWithReturnUrl;
          } else {
            navigate(routes.profile);
          }
        }
        return response.data;
      } else {
        throw response;
      }
    } catch (e: FetchError | any) {
      store.dispatch(errorSlice.actions.setCustomError(e.errors));
    }
  },
);
