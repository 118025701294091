import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { ModalTypes, modalSlice } from "@/store/modal";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

/** Компонент-контейнер:
 * - При монтировании компонента проверяет наличие modalType в URL
 * - Если modalType есть, то устанавливает его в store
 * - Удаляет modalType из URL
 * - Перенаправляет на новый URL без modalType
 */

export const ModalContainer = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalType = searchParams.get("modalType");

    if (modalType) {
      dispatch(modalSlice.actions.setModalType(modalType as ModalTypes));

      searchParams.delete("modalType");

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, []);

  return <div>{children}</div>;
};
